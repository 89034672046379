import React, { Component } from 'react';

export class PeopleCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 'home',
        };
    }

    handleSelect = (selectedKey) => {
        this.setState({ selectedTab: selectedKey });
    };
    u

    render() {
        const isMobile = window.innerWidth <= 767;
        const widthImg = isMobile ? 120 : 160;

        const { image, alt, name, office, summary } = this.props;
        return (
            <div className='people-block'>
                <img
                    src={image}
                    width={widthImg}
                    // height="120"
                    className="d-inline-block align-top people-image"
                    alt={alt}
                    style={{maxHeight: isMobile ? '120px' : '200px'}}
                />
                <div className='card-person'>
                    <span className='people-name'>{name}</span>
                    <span className='people-office'>{office}</span>
                    <span className='people-summary'>{summary}</span>
                </div>
            </div>
        );
    }
}

export default PeopleCard;
