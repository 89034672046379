import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import About from './Screens/About.js';
import { Nav, Navbar } from 'react-bootstrap';
import Footer from './Components/Footer';
import Contact from './Screens/Contact.js';
import Articles from './Screens/Articles.js';
import Monitor from './Screens/Monitor.js';
import ArticleDetail from './Screens/ArticleDetail.js';
import { BsPersonCircle } from "react-icons/bs";
import { createRoot } from 'react-dom/client';

class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedTab: '',
            loading: false
        };
    }

    handleClickAccessData = () => {
        // window.location.href = 'http://54.196.209.0/';      
    }

    handleSelect = (selectedKey) => {
        this.setState({ selectedTab: selectedKey });
    };

    openInNewTab = (link) => {
        window.open(link);
    };

    render() {
        const { selectedTab } = this.state;

        const isMobile = window.innerWidth <= 767;
        const heightImg = isMobile ? 200 : 600;
        const heightBiggerImg = isMobile ? 250 : 650;

        const currentPath = window.location.pathname.slice(1);        

        return (
            <Router>
                <div>                    
                    <div className="container" >
                        <Navbar bg="white" expand="lg" style={{ paddingLeft: '2%' }}>
                            <Navbar.Brand as={Link} to="/">
                                <img
                                    src="/images/logo.png"
                                    width="200"
                                    // height="30"
                                    className="d-inline-block align-top"
                                    alt="Logo"
                                />
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto w-100 justify-content-between" onSelect={this.handleSelect} activekey={selectedTab}>
                                    <div className='d-flex div-link'>
                                        <Nav.Link                                            as={Link}
                                            to="/"
                                            eventKey="home"
                                            style={{ color: currentPath === 'home' || currentPath === '' ? 'purple' : 'blue', textDecoration: 'none', fontWeight: 'bold' }}
                                            className='home-link'
                                        >
                                            Início
                                        </Nav.Link>
                                        <Nav.Link
                                            as={Link}
                                            to="/about"
                                            eventKey="about"
                                            style={{ color: currentPath === 'about' ? 'purple' : 'blue', textDecoration: 'none', fontWeight: 'bold' }}
                                            className='nav-link'
                                        >
                                            Sobre Nós
                                        </Nav.Link>
                                        <Nav.Link
                                            as={Link}
                                            to="/arcticle"
                                            eventKey="arcticle"
                                            style={{ color: currentPath === 'arcticle' ? 'purple' : 'blue', textDecoration: 'none', fontWeight: 'bold'}}
                                            className='nav-link'
                                            >
                                            Artigos
                                        </Nav.Link>
                                        <Nav.Link
                                            as={Link}
                                            to="/monitor"
                                            eventKey="monitor"
                                            style={{ color: currentPath === 'monitor' ? 'purple' : 'blue', textDecoration: 'none', fontWeight: 'bold'}}
                                            className='nav-link'
                                            >
                                            Plataforma de Dados
                                        </Nav.Link>
                                        {
                                            isMobile ?
                                                <Nav.Link
                                                    href="/contact"
                                                    style={{ color: currentPath === 'contact' ? 'purple' : 'blue', textDecoration: 'none', fontWeight: 'bold' }}
                                                    className='nav-link'
                                                >
                                                    Contato
                                                </Nav.Link>
                                                : null
                                        }

                                    </div>
                                    {
                                        isMobile ?
                                            null
                                            :
                                            <div className='contact-block'>
                                                <BsPersonCircle style={{ color: 'white', fontSize: '24px' }} />

                                                <Nav.Link href="/contact" style={{ color: 'white', textDecoration: 'none' }}>Contato</Nav.Link>
                                            </div>
                                    }


                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>

                    <Routes>
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/arcticle" element={<Articles />} />
                        <Route path="/monitor" element={<Monitor />} />
                        <Route path="/article-details/:id" element={<ArticleDetail />} />
                        {/*<Route path="/article-create" element={<ArticleCreate />} />} */}
                        <Route path="/" element={<div className="container container-inner">
                            {/* <h1 style={{ textAlign: 'center' }}>Hello, React!</h1>     */}
                            <div className='text-and-img-block'>
                                <div className='access-data-text-block'>
                                    <span className='title-text'>Acesso à séries de dados fiscais públicos consistentes</span>
                                    <span className='subtitle-text mt-3'>
                                        Somos movidos pelo esforço de pessoas que acreditam no poder dos dados abertos e de qualidade.
                                    </span>
                                    <Link to="/monitor" className='people-block' style={{ marginBottom: '29px', textDecoration: 'none', color: 'inherit' }}>
                                        <span className='subtitle-text access-data-button' style={{ zIndex: 1 }}>
                                            acesse nossos dados
                                        </span>
                                    </Link>
                                </div>
                                {
                                    isMobile ?
                                        null :

                                        <div className='d-flex flex-row justify-content-end' style={{ marginRight: '1px', display: 'none' }}>
                                            <img
                                                src="/images/Pag1 Img1.png"
                                                // width="90"
                                                height={heightImg}
                                                className="d-inline-block align-top background-img-1-1"
                                                alt="img"
                                            />
                                            <img
                                                src="/images/Quadrado 1 pag 1.png"
                                                // width="90"
                                                height={heightImg}
                                                className="d-inline-block align-top background-img-1"
                                                alt="img"
                                            />
                                        </div>
                                }

                            </div>
                            <div className='text-and-img-block'>
                                {
                                    isMobile ?
                                        null :
                                        <div className='d-flex flex-row justify-content-start'>
                                            <img
                                                src="/images/Pag1 Img4.png"
                                                // width="90"
                                                height={heightBiggerImg}
                                                className="d-inline-block align-top background-img-2-1"
                                                alt="img"
                                            />
                                            <img
                                                src="/images/Quadrado 2 pag 1.png"
                                                // width="90"
                                                height={heightImg}
                                                className="d-inline-block align-top background-img-2"
                                                alt="img"
                                            />
                                        </div>
                                }                                

                                <div className='access-data-text-block access-data-text-block-left'>
                                    <span className='title-text'>Nossa plataforma</span>
                                    <span className='subtitle-text mt-3'>
                                        Com o objetivo de promover a transparência, qualidade e acesso fácil as informações fiscais das três esferas da federação, o Monitor FiscalData tem sido desenvolvido com o apoio de quatro instituições: a CCiF e as federações dos fiscais municipais, estaduais e federais.
                                    </span>
                                    <span className='subtitle-text mt-5'>
                                        Assim, nasceu nossa plataforma, que, utilizando tecnologia da informação, facilita o acesso e a elaboração de pesquisas. Disponibilizamos informações de qualidade para a gestão pública e para o público em geral.
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div className="line-style">
                                    <div className="line-style-left"></div>
                                    <span className="text-style">Parceiros</span>
                                    <div className="line-style-left-right"></div>
                                </div>

                                <div className='support-cards-block'>
                                    <div className='support-card' onClick={()=> window.open("https://ccif.com.br/", "_blank")}>
                                        <div className='support-text-block'>
                                            <span>
                                                Centro de Cidadania Fiscal
                                            </span>
                                        </div>
                                        <div className='support-img-block'>
                                            <img
                                                src="/images/ccif.png"
                                                // width="90"
                                                height="90"
                                                className="d-inline-block align-top"
                                                alt="img"
                                            />
                                        </div>
                                    </div>
                                    <div className='support-card' onClick={()=> window.open("https://www.fenafim.org.br/site/", "_blank")}>
                                        <div className='support-text-block card-purple'>
                                            <span>
                                                Federação Nacional dos Auditores e Fiscais de Tributos Municipais
                                            </span>
                                        </div>
                                        <div className='support-img-block'>
                                            <img
                                                src="/images/fenafim.jpeg"
                                                // width="90"
                                                height="90"
                                                className="d-inline-block align-top"
                                                alt="img"
                                            />
                                        </div>
                                    </div>
                                    <div className='support-card' onClick={()=> window.open("https://www.febrafite.org.br/", "_blank")}>
                                        <div className='support-text-block'>
                                            <span>
                                                Federação Brasileira de Associações de Fiscais de Tributos Estaduais
                                            </span>
                                        </div>
                                        <div className='support-img-block'>
                                            <img
                                                src="/images/febrafite.png"
                                                // width="90"
                                                height="120"
                                                className="d-inline-block align-top"
                                                alt="img"
                                            />
                                        </div>
                                    </div>
                                    <div className='support-card' onClick={()=> window.open("https://www.sindifisconacional.org.br/", "_blank")}>
                                        <div className='support-text-block card-purple'>
                                            <span>
                                                Sindicato Nacional dos Auditores-Fiscais da Receita Federal do Brasil
                                            </span>
                                        </div>
                                        <div className='support-img-block'>
                                            <img
                                                src="/images/sindifisco.png"
                                                // width="90"
                                                height="90"
                                                className="d-inline-block align-top"
                                                alt="img"
                                            />
                                        </div>
                                    </div>
                                    <div className='support-card' onClick={()=> window.open("https://samambaia.org/", "_blank")}>
                                        <div className='support-text-block'>
                                            <span>
                                                Samambaia.org
                                            </span>
                                        </div>
                                        <div className='support-img-block'>
                                            <img
                                                src="/images/samambaia.png"
                                                // width="90"
                                                height="200"
                                                className="d-inline-block align-top"
                                                alt="img"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <Footer selectedTab={this.state.selectedTab} style={{ marginTop: '-135px' }} />
                        </div>} />
                    </Routes>
                </div>
            </Router>

        );
    }
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);
