import React, { Component } from 'react';
import { Footer } from '../Components/Footer';
import PeopleCard from '../Components/PeopleCard';

const peopleList = [
    {
        image: '/images/people/priscila.jpeg',
        alt: 'Priscila',
        name: 'Priscila Kaiser',
        office: 'Economista',
        summary: 'Mestre em Economia pela PUCRS e pós-graduada em Data Science e Big Data pela PUC-Minas. Também, possui graduação em economia pela UFRGS. Atualmente é consultora de finanças públicas.',
    },
    {
        image: '/images/people/uriel.jpeg',
        alt: 'Uriel',
        name: 'Uriel kveller',
        office: 'Economista',
        summary: 'Mestre em Análise de Política Econômica pela Universidade de Paris e Mestre em Economia pela Unicamp. Possui graduação em Economia pela UFRGS. Atualmente é consultor de finanças públicas.',
    },
    {
        image: '/images/people/lucas.jpeg',
        alt: 'Lucas',
        name: 'Lucas Brum',
        office: 'Profissional de Dados',
        summary: 'Graduado em Análise e Desenvolvimento de Sistemas pela FAQI. Atualmente é consultor de Engenharia de Dados.',
    },
];

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date()
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {        
    }

    render() {
        const isMobile = window.innerWidth <= 767;
        const widthImg = isMobile ? 300 : 500;

        return (
            <div className="container">
                <div className='text-and-img-block justify-content-evenly'>
                    <span className='title-text' style={{ marginLeft: isMobile ? '10px' : 0 }} >Sobre Nós</span>

                    <img
                        src="/images/Pag2 Img1.png"
                        width={widthImg}
                        //   height="60"
                        className="d-inline-block align-top"
                        alt="Logo"
                        style={{ zIndex: 10, alignSelf: isMobile ? 'flex-end' : 'flex-start' }}
                    />

                </div>
                <img
                    src="/images/Listra.png"
                    // width="600"
                    //   height="200"
                    className="d-inline-block align-top"
                    alt="Logo"
                    style={{
                        height: '150px',
                        width: '100%',
                        marginTop: '-100px'
                    }}
                />
                <div className='about-us-text-block'>
                    <div className='about-us-text-inner-block'>
                        <span className='mt-5'>
                            A FiscalData nasceu com o objetivo de integrar a teoria das finanças públicas e econômica com a tecnologia da informação, facilitando o acesso e a elaboração de pesquisas. Trabalhamos na disponibilização de informações de qualidade para a gestão pública e para o público em geral. Como uma instituição think thank, desempenhamos um papel de advocacy para políticas públicas. Elucidamos tópicos econômicos e sobre as finanças públicas do país. Nosso propósito é o de simplificar o acesso à informação, mobilizar e articular os atores de atuação de finanças públicas e que contribuem para o debate público transparente e desenvolvimento do país.
                        </span>
                        <span className='mt-4 title-blue-text'>
                            Atuação
                        </span>
                        <span className='mt-4'>
                            - Desenvolvimento de ambientes de banco de dados com informações de finanças públicas e socioeconômicas
                        </span>
                        <span>
                            - Estudos econômicos, notas e boletins técnicos
                        </span>
                        <span>
                            - Modelos econômicos e econométricos
                        </span>
                        <span>
                            - Desenvolvimento de aplicações para disponibilização de informações na web
                        </span>
                    </div>
                </div>
                <div className='text-and-img-block align-items-center cards cards-block'>

                {peopleList.map((person, index) => (
                    <PeopleCard
                    key={index}
                    image={person.image}
                    alt={person.alt}
                    name={person.name}
                    office={person.office}
                    summary={person.summary}
                    />
                ))}
                </div>
                <Footer />
            </div>
        );
    }
}

export default About;
